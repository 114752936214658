<template>
<div class="sup__request">
  <i @click="close" class="sup__request__toggle fas fa-times" style="color: #a9a9a9"></i>
  <div class="sup__request__header">
    <span class="sup__request__header__icon"><QuestionIco/></span>
    <span class="sup__request__header__date">{{ registrationDate }}</span>
    <span v-if="role === 'manager'" class="sup__request__header__employee-and-company">{{ $store.state.me.name }} для
      <router-link v-if="clientRole === 'partner'" :to="{ name: 'company', params: { id: companyId } }">{{ companyName }} (клиент)</router-link>
      <span v-else>{{ clientName }} (сотрудник)</span>
    </span>
  </div>
  <div class="sup__request__border"></div>
  <!-- Appeals -->
  <div v-if="role === 'manager'" class="sup__request__body">
    <span>Задача для <b>{{ $store.state.me.name }}</b> до {{lastDate}}</span>
    <div class="sup__request__body__list">
      <div class="sup__request__body__list__qa"
        v-for="(item, index) in questionAndAnswer"
        :key="index"
      >
        <div><b>Вопрос: </b>{{ item.question }}</div>
        <div v-if="item.question_files && item.question_files.length > 0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <b>Файлы:</b>
          <ul>
            <li
              v-for="file in item.question_files"
              :key="file.uuid"
            >
              <a class="sup__request__files" @click="downloadFile(file.uuid, file.type, item.uuid)" v-tooltip:bottom="'Скачать'">{{file.name}}</a>
            </li>
          </ul>
        </div>
        <div v-if="item.answer !== null"><b>Ответ: </b>{{ item.answer }}</div>
        <div v-if="item.answer_files && item.answer_files.length > 0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <b>Файлы:</b>
          <ul>
            <li
              v-for="file in item.answer_files"
              :key="file.uuid"
            >
              <a class="sup__request__files" @click="downloadFile(file.uuid, file.type, item.uuid)" v-tooltip:bottom="'Скачать'">{{file.name}}</a>
            </li>
          </ul>
        </div>
      </div>
        <div v-if="questionAndAnswer[questionAndAnswer.length - 1].answer === null && status === 'Открыт'">
        <div style="width: 100%; display: flex; flex-flow: row wrap; justify-content: space-between; align-items: center;">
          <b>Ответ: </b>
          <select v-model="modelPattern">
            <option selected disabled>Шаблоны сообщений</option>
            <option v-for="item in messageTemplates" :key="item.id" :value="item.message">{{ item.title }}</option>
          </select>
        </div>
        <div class="input-form">
          <textarea
            v-model="modelTextarea"
            class="sup__request__body__list__answer"
            id="message"
            rows="5"
            placeholder="Напишите сюда ваш уточняющий вопрос"
            required
          />
          <div class="dropzone-container">
            <vue-dropzone
              id='dropzone2'
              ref='dropzone'
              @vdropzone-files-added="filesPicked"
              @vdropzone-upload-progress="fileProgress"
              @vdropzone-error="fileError"
              @vdropzone-removed-file="fileRemove"
              @vdropzone-canceled="fileCancel"
              @vdropzone-queue-complete="queueComplete"
              :includeStyling="false"
              useCustomSlot
              :options="dropzoneOptionsBux">
              <div class="dropzone-icon">
                <i>
                  <PaperClip/>
                </i>
              </div>
            </vue-dropzone>
          </div>
        </div>
        <div id="dz-previews" class="dz-previews"></div>
        <i class="files__help">Подсказка: количество отправляемых файлов: 4; максимальный размер файла: 2 Мб; доступные типы: .jpg, .jpeg, .pdf</i>
        <button v-if="activeButton" type="button" class="btn" @click="UpdateQuestionAndAnswer">ОТПРАВИТЬ СООБЩЕНИЕ</button>
        <button v-else type="button" class="btn btn--disable">ОТПРАВКА...</button>
      </div>
    </div>
  </div>
  <!-- Support -->
  <div v-else class="sup__request__body">
    <div class="sup__request__body__list">
      <div class="sup__request__body__list__qa"
        v-for="(item, index) in modelTempArr"
        :key="index"
      >
        <div><b>Вопрос: </b>{{ item.question }}</div>
        <div v-if="item.question_files && item.question_files.length > 0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <b>Файлы:</b>
          <ul>
            <li
              v-for="file in item.question_files"
              :key="file.uuid"
            >
              <a class="sup__request__files" @click="downloadFile(file.uuid, file.type, item.uuid)" v-tooltip:bottom="'Скачать'">{{file.name}}</a>
            </li>
          </ul>
        </div>
        <div v-if="item.answer !== null"><b>Ответ: </b>{{ item.answer }}</div>
        <div v-if="item.answer_files && item.answer_files.length > 0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <b>Файлы:</b>
          <ul>
            <li
              v-for="file in item.answer_files"
              :key="file.uuid"
            >
              <a class="sup__request__files" @click="downloadFile(file.uuid, file.type, item.uuid)" v-tooltip:bottom="'Скачать'">{{file.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="status === 'Открыт'" class="addition">
        <div class="addition__header">
          <label v-if="questionAndAnswer[questionAndAnswer.length - 1].answer !== null" class="addition__header__item" @click="addition = !addition">
            <i>
              <DownIcon/>
            </i>
            <a>Дополнить вопрос</a>
          </label>
          <button type="button" class="btn" @click="changeStatus">ЗАКРЫТЬ ОБРАЩЕНИЕ</button>
        </div>
        <div v-if="addition" class="addition__body">
          <div class="input-form">
            <textarea
              v-model="modelTextarea"
              class="sup__request__body__list__answer"
              id="message"
              rows="5"
              placeholder="Напишите сюда ваш уточняющий вопрос"
              required
            />
            <div class="dropzone-container">
              <vue-dropzone
                id='dropzone'
                ref='dropzone'
                @vdropzone-files-added="filesPicked"
                @vdropzone-upload-progress="fileProgress"
                @vdropzone-error="fileError"
                @vdropzone-removed-file="fileRemove"
                @vdropzone-canceled="fileCancel"
                @vdropzone-queue-complete="queueComplete"
                :includeStyling="false"
                useCustomSlot
                :options="dropzoneOptions">
                <div class="dropzone-icon">
                  <i>
                    <PaperClip/>
                  </i>
                </div>
              </vue-dropzone>
            </div>
          </div>
          <div id="dz-previews" class="dz-previews"></div>
          <i class="files__help">Подсказка: количество отправляемых файлов: 4; максимальный размер файла: 2 Мб; доступные типы: .jpg, .jpeg, .pdf</i>
          <button v-if="activeButton" type="button" class="btn btn--addition" @click="addQuestion">ОТПРАВИТЬ СООБЩЕНИЕ</button>
          <button v-else type="button" class="btn btn--disable">ОТПРАВКА...</button>
        </div>
      </div>
    </div>
  </div>
  <div class="sup__request__border"></div>
  <div class="sup__request__status">
    <span>Статус {{ status }}</span>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
// import ConfirmIcon from '@/components/svg/ConfirmIcon'
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import DownIcon from '@/components/svg/DownIcon'
import UPDATE_SUPPORT_REQUEST from '@/graphql/mutations/UpdateSupportRequest.gql'
import ADD_QUESTION_AND_ANSWER from '@/graphql/mutations/AddQuestionAndAnswer.gql'
import UPDATE_QUESTION_AND_ANSWER from '@/graphql/mutations/UpdateQuestionAndAnswer.gql'
import ALL_MESSAGE_TEMPLATES from '@/graphql/queries/AllMessageTemplates.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper.js'
import QuestionIco from '@/components/svg/question'

const _graphQlHelper = new GraphQLHelper()

const BASE_URL = process.env.VUE_APP_HTTP

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <span class="dz-remove" data-dz-remove><a>Удалить</a></span>
</div>
`

export default {
  name: 'SupportRequest',
  components: {
    // ConfirmIcon,
    PaperClip,
    vueDropzone: vue2Dropzone,
    DownIcon,
    QuestionIco
  },
  props: ['id', 'client', 'clientName', 'companyId', 'companyName', 'questionAndAnswer', 'registrationDate', 'lastDate', 'status', 'theme', 'role', 'clientRole', 'selectedRequest'],
  data () {
    return {
      dropzoneOptions: {
        previewTemplate: getTemplate(),
        url: 'https://httpbin.org/post',
        parallelUploads: 1,
        maxFiles: 4,
        maxFilesize: 2, // 2Mb
        acceptedFiles: 'image/jpg, image/jpeg, application/pdf',
        previewsContainer: '#dz-previews'
      },
      dropzoneOptionsBux: {
        previewTemplate: getTemplate(),
        url: 'https://httpbin.org/post',
        parallelUploads: 1,
        maxFiles: 5,
        maxFilesize: 8388608, // 8Mb
        acceptedFiles: 'image/jpg, image/jpeg, application/pdf, application/x-zip-compressed',
        previewsContainer: '#dz-previews'
      },
      activeButton: true,
      select: 0,
      file: null,
      files: [],
      addition: false,
      textareaValue: '',
      tempArr: [],
      qa: [],
      pattern: 'Шаблоны сообщений',
      messageTemplates: []
    }
  },
  beforeMount () {
    if (this.$store.state.me.role !== 'partner') {
      this.$apollo.mutate({
        mutation: ALL_MESSAGE_TEMPLATES,
        variables: {
          responsible_id: this.$store.state.me._id,
          type: 'appeal'
        },
        update: (_, { data }) => {
          if (data) {
            this.messageTemplates = data.allMessageTemplates
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Ошибка загрузки шаблонов сообщений.'
          })
          throw new Error(error)
        })
      })
    }
  },
  computed: {
    modelPattern: {
      get () {
        return this.pattern
      },
      set (value) {
        this.textareaValue = value
      }
    },
    modelTextarea: {
      get () {
        return this.textareaValue
      },
      set (value) {
        this.textareaValue = value.replace(/\s+/g, ' ').trim()
      }
    },
    modelTempArr: {
      get () {
        if (this.tempArr.length === 0) {
          return this.questionAndAnswer
        } else {
          return this.tempArr
        }
      },
      set (value) {
        this.tempArr = value
      }
    }
  },
  methods: {
    queueComplete () {
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка при загрузке файлов'
            })
          } else {
            this.$notify({
              group: 'lsg-notify',
              text: 'Файлы загружены'
            })
          }
        })
      }
    },
    fileCancel (file) {
      this.$refs.dropzone.removeAllFiles()
      this.$notify({
        group: 'lsg-notify',
        text: `Отмена загрузки файла ${file.name}`
      })
    },
    fileRemove (file, error, xhr) {
      const updateFiles = []
      this.files.forEach(elem => {
        if (elem.upload.uuid !== file.upload.uuid) {
          updateFiles.push(elem)
        }
      })
      this.files = updateFiles
    },
    fileError (file, message, xhr) {
      file.previewElement.style.setProperty('background-color', '#FF0000')
      this.$notify({
        group: 'lsg-notify',
        text: 'Файл не может быть загружен'
      })
    },
    fileProgress (file, progress, bytesSent) {
      file.previewElement.style.setProperty('--progress', progress + '%')
    },
    filesPicked (e) {
      this.files = e
    },
    submitDropFile () {
      const url = `${BASE_URL}/files`
      let formData = new FormData()
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i]
        formData.append(file.upload.uuid, file)
      }
      axios.post(url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Файлы успешно отправлены'
        })
      }).catch(() => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка при отправке файлов'
        })
      })
    },
    downloadFile (filename, mimetype, uuid) {
      try {
        window.open(`${BASE_URL}/files?file=${filename}&mimetype=${mimetype}&user=${this.$store.state.me._id}`)
      } catch (e) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка при скачивании файла'
        })
      }
    },
    onMessageChange (select) {
      this.textareaValue = select.value
    },
    close () {
      this.$store.state.isEmpty = true
    },
    thumbGenerated (e, dataUrl) {
      this.file = e.name
    },
    formatDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY HH:MM') : ''
    },
    async changeStatus () {
      await this.$apollo.mutate({
        mutation: UPDATE_SUPPORT_REQUEST,
        variables: {
          id: this.id
        },
        update: (_, { data }) => {
          if (data) {
            this.$emit('update:status', 'Закрыт')
            this.$notify({
              group: 'lsg-notify',
              text: 'Статус успешно изменен.'
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async addQuestion () {
      if (this.modelTextarea === '') {
        this.$notify({
          group: 'lsg-notify',
          text: 'Отсутствует вопрос.'
        })
        throw new Error('Отсутствует вопрос.')
      }
      this.tempArr = [...this.questionAndAnswer, { question: this.modelTextarea, answer: null }]
      this.addition = false
      let filesArr = []
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка. Повторите отправку, дождавшись загрузки всех файлов'
            })
            throw new Error('Ошибка. Повторите отправку, дождавшись загрузки всех файлов')
          } else {
            filesArr.push(elem.upload.uuid)
          }
        })
      }
      this.files = await Promise.all(this.files)
      filesArr = await Promise.all(filesArr)
      this.activeButton = false
      await this.$apollo.mutate({
        mutation: ADD_QUESTION_AND_ANSWER,
        variables: {
          support_request_id: this.id,
          question: this.modelTextarea,
          question_files: filesArr
        },
        update: (_, { data }) => {
          this.activeButton = true
          if (data) {
            try {
              this.submitDropFile()
            } catch (e) {
              this.$notify({
                group: 'lsg-notify',
                text: e
              })
            }
            this.$emit('update:questionAndAnswer', this.qa)
            this.$notify({
              group: 'lsg-notify',
              text: 'Ваш вопрос успешно отправлен.'
            })
            this.$router.go()
          }
        }
      }).catch(error => {
        this.activeButton = true
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
        throw new Error(error)
      })
      this.modelTextarea = ''
    },
    async UpdateQuestionAndAnswer () {
      if (this.modelTextarea === '') {
        this.$notify({
          group: 'lsg-notify',
          text: 'Отсутствует ответ.'
        })
        throw new Error('Отсутствует ответ.')
      }
      this.tempArr = [...this.questionAndAnswer]
      this.tempArr[this.tempArr.length - 1].answer = this.modelTextarea
      let filesArr = []
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка. Повторите отправку, дождавшись загрузки всех файлов'
            })
            throw new Error('Ошибка. Повторите отправку, дождавшись загрузки всех файлов')
          } else {
            filesArr.push(elem.upload.uuid)
          }
        })
      }
      this.files = await Promise.all(this.files)
      filesArr = await Promise.all(filesArr)
      this.activeButton = false
      await this.$apollo.mutate({
        mutation: UPDATE_QUESTION_AND_ANSWER,
        variables: {
          id: this.questionAndAnswer[this.questionAndAnswer.length - 1].id,
          answer: this.modelTextarea,
          answer_files: filesArr
        },
        update: (_, { data }) => {
          if (data) {
            this.activeButton = true
            try {
              this.submitDropFile()
            } catch (e) {
              this.$notify({
                group: 'lsg-notify',
                text: e
              })
            }
            this.$emit('update:questionAndAnswer', this.qa)
            this.$notify({
              group: 'lsg-notify',
              text: 'Ваш ответ успешно отправлен.'
            })
            this.$router.go()
          }
        }
      }).catch(error => {
        this.activeButton = true
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
          throw new Error(error)
        })
      })
      this.modelTextarea = ''
    }
  }
}
</script>

<style lang="stylus">
.btn--disable {
  background $c979797 !important
  color $white !important
  border-color $c979797 !important

  &:hover {
    background $c979797 !important
    cursor auto
  }

  &:active {
    transform none !important
  }
}

.sup__request__files {
  overflow auto
  text-overflow ellipsis
  cursor pointer
  cursor pointer
  color $orange

  &:hover {
    text-shadow: 1px 1px 1px $orange
  }
}

.files__help {
  display inline-block
  margin-bottom 15px
}

.input-form {
  display flex
  flex-direction row
  margin-top 15px
}

.upload-file
  opacity 0
  width 100%
  height 200px !important
  position absolute
  cursor pointer

.dropzone-container {
  min-width 60px
  min-height 60px
  margin-left 10px
}

#dropzone, #dropzone2 {
  height 100%
}

.dropzone-icon {
  display flex
  justify-content center
  align-items center
  text-align center
  background: $white;
  border: 1px dashed $c4E555A;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left 10px
  max-width 350px
  width 60px
  height 100%
  cursor pointer

  &--full {
    width 100%
    margin-bottom 30px
  }

  i {
    width 22px
    height 22px
    display flex
    align-items center
    justify-content center
    margin-right 12px

    svg {
      width 100%
      height 100%
    }
  }
}
</style>

<style lang="stylus">

  .sup__select {
    width 200px
    margin-right 20px
  }

  .sup__request {
    position relative

    &__header__icon {
      color $orange !important
      svg {
        width 18px
        height 18px

        path[fill] {
          fill $orange !important
        }
        path {
          stroke $orange !important
        }
      }
    }

    &__body__list__qa {
      margin-bottom 20px

      > div {
        white-space pre-wrap
      }
    }

    &__bottom {
      display flex
      justify-content space-between
      flex-wrap wrap
      height 90px

      > .btn {
        height 40px !important
      }
    }

    &__toggle {
      position absolute
      top 15px
      right 15px
      cursor pointer
      font-size 20px
      transition opacity .25s

      &:hover {
        opacity 0.7
      }
    }
  }

  .addition {
    &__header {
      width 100%
      display flex
      flex-direction row
      justify-content space-between
      align-items center
      margin-top 10px

      &__item {
        display flex
        align-items center

        > i {
          display inline-block
          height 16px
          color #ff8000
        }
      }

      > .btn {
        min-height 20px !important
        padding 3px 10px !important
      }
    }
    &__body {
      margin-top 10px
      > .btn--addition {
        margin-top 20px
      }
    }
  }

  #dropzone, #dropzone2 > .dz-message {
    display flex
    height 100%
  }

  #dz-previews {
    display flex
    flex-direction column
    margin 15px 0

    .dz-preview {
      position relative
      display flex !important
      flex-direction row !important
      padding 5px
      border 1px dashed $c4E555A
      border-radius 10px
      max-width 100% !important
      justify-content space-between
      z-index 2

      &:not(:last-child) {
        margin-bottom 10px
      }

      &::after {
        content: ''
        absolute left top
        background-color $anothergreen
        width var(--progress)
        opacity 0.5
        height 100%
        border-radius 10px
        z-index 1
      }
    }

    .dz-details {
      display flex !important
      flex-wrap nowrap !important
      flex-direction row-reverse !important
      z-index 2
    }

    .dz-filename {
      margin-right 10px
    }

    .dz-remove {
      z-index 2
      cursor: pointer
    }

    .dz-progress {
      position absolute
      top 0
      left 0
      background-color $green
      width 100%
    }

    .dz-upload {
      background-color $green
    }
  }
</style>
